import * as React from "react";
import Link from "gatsby-link";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Para, PrimaryButton } from "../components/basic";

const pageSchemaContent = {
  name: "Success",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://imchetanyadav.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Contact",
        item: "https://imchetanyadav.com/contact",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Success",
        item: "https://imchetanyadav.com/success",
      },
    ],
  },
};

export default () => (
  <Layout>
    <SEO
      pageTitle="Success"
      pageDescription="Thanks for contacting me!"
      pageSchemaContent={pageSchemaContent}
    />
    <div
      style={{
        paddingTop: "100px",
        textAlign: "center",
      }}
    >
      <Para>Thanks for contacting</Para>

      <Link to="/">
        <PrimaryButton>Go Back</PrimaryButton>
      </Link>
    </div>
  </Layout>
);
